import { useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PayPalButton from "../../molecule/PayPal/PayPalButtons";


const Checkout = (props) => {
    const { totalPrice } = props;
    const [orderAmount, setOrderAmount] = useState("20.00"); // Replace with dynamic data if needed
    const [orderDescription, setOrderDescription] = useState("Purchase of XYZ items");
    const [currency, setCurrency] = useState("USD");
    
    // Callback to handle order success
  const handleSuccess = (data) => {
    alert(`Transaction completed by ${data.payerName}`);
    console.log("Transaction Details:", data.details);
    // Use shippingAddress and payerName as needed
  };
    
    return (
        <div className="checkoutOuter">
            {/* <div>
                <label htmlFor="currency">Select Currency: </label>
                <select
                id="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                >
                <option value="USD">USD</option>
                <option value="HKD">HKD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                </select>
            </div> */}

            <PayPalButton
                amount={orderAmount}
                orderDetails={orderDescription}
                currency={currency}
                onSuccess={handleSuccess}
            />
        </div>
    )
}

export default Checkout;