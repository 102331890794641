import './FooterNew.css'

const FooterNew = () => {

    return (
        <div className="footer">
            <div>Contact</div>
            <div>Privacy</div>
            <div>Terms of Service</div>
            <div>Intellectual Property</div>
        </div>
    )

}

export default FooterNew;