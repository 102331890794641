import { useContext, useState, useEffect } from 'react';
import SliderToggle from '../../atom/Toggle/Toggle';
import { selectContext, operatingContext } from '../../../context/Context';
import './ImageThumbPanel.css'
import { Button } from '@chakra-ui/react';
import ImageAlbum from '../ImageAlbum/ImageAlbum';

const ImageThumbPanel = () => {

    const {uploadedImage, setUploaded, setIsOpen} = useContext(selectContext);
    const {setIsDrawerOpen} = useContext(operatingContext);
    const [preview, setPreview] = useState(null)
    
    // const handleSizeSelect = (size) => {
    //     setSelectedSize(size)
    // }

    const handleNewImage = () => {
        //setUploaded(null);
        setIsDrawerOpen(true);
    }

    useEffect(() => {
        if (!uploadedImage) {
            setPreview(undefined)
            return
        }
    
        const objectUrl = URL.createObjectURL(uploadedImage)
        setPreview(objectUrl)
    
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [uploadedImage])

    return (
        <div className='imageThumbPanel'>
            {/* <div className='imageThumbLeft'>
                <div className='imageThumbOuter'>
                    <div style={{width: '160px'}}><SliderToggle /></div>
                </div>
            </div> */}

                <div className='imageThumbOuter'>
                    <div className='imageThumbHeader'>
                        <div className='imageThumbTitle'>Image Album:</div>
                        {/* <div><Button style={{'background': '#ff0098', marginRight: 0, marginBottom: '4px'}} colorScheme='blue' mr={3} onClick={handleNewImage}>+</Button></div> */}
                    </div>
                    <div className='ImageWrapper'>
                        {/* <div className='imageDisplay'>
                            {uploadedImage &&  <img src={preview} className='thumbImage' style={{'aspect-ratio': 'auto'}} alt='Uploaded' /> }
                        </div> */}
                        <ImageAlbum />
                    </div>
                </div>

        </div>
    )
}

export default ImageThumbPanel;