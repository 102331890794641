import React from "react";
import { selectContext } from "../../../context/Context";
import RNTSF from '../../../assets/images/MerchTSRoundFront.png'
import TSB from '../../../assets/images/MerchTSBack.png'
import VNTSF from '../../../assets/images/MerchTSVeeFront.png'
import POHDF from '../../../assets/images/MerchHDPullOverFront.png'
import HDB from '../../../assets/images/MerchHDBack.png'
import ZPHDF from '../../../assets/images/MerchHDZipFront.png'
import ColourPanelMini from "../ColourPanelMini/ColourPanelMini";

import './MerchPanel.css'
import MerchCarousel from "../MerchCarousel/MerchCarousel";

const MerchPanel = () => {
    const {selectedMerch, setSelectedMerch} = React.useContext(selectContext);

    const boxes = [
        {type: 'TRound',  desc: 'Classic short sleeve T-Shirt made from soft and comfortable cotton, ideal for everyday use. The design is available in a variety of colours.', images: [{id:1, name: RNTSF}, {id:2, name: TSB}]},
        {type: 'TVNeck',  desc: 'This premium V-neck made with soft and comfortabke cotton, is ideal for everyday use. The unisex design is avaiable in avariety of colours.', images: [{id:1, name: VNTSF}, {id:2, name: TSB}]},
        {type: 'HoodiePlain',  desc: 'This pullover Hoodie is made with a cotton polyester mix and features a drawstring, kangaroo pocket, ribbed cuffs and waistband.  The unisex design is avaiable in avariety of colours.', images: [{id:1, name: POHDF}, {id:2, name: HDB}]},
        {type: 'HoodieZip',  desc: 'This premium zipped hoodie is made of a blend of high quality  materials and features a full front zip. The unisex design is avaiable in avariety of colours.', images: [{id:1, name: ZPHDF}, {id:2, name: HDB}]},
    ]

    const handleBoxClick = (index, block) => {
        //setisExpanded(isExpanded => !isExpanded)
        setSelectedMerch(block.type)
    }

    const renderBlocks = () => {
        const returnBlocks = boxes.map((block, index) => {
            let blockStyle
            if((block.type) === selectedMerch){
                blockStyle = 'merchItemPanel merchItemPanelSelected';
            } else {
                blockStyle = 'merchItemPanel';
            }

            return ( 
                // 
                //     <div className={blockStyle} onClick={() => handleBoxClick(index, block)}>
                //         <img className="baseImage" src={block.image} alt="" />
                //     </div>
                // </li>
                <li key={index}>
                    <div className={blockStyle} onClick={() => handleBoxClick(index, block)}>
                        <div className="merchItemImage">
                            <MerchCarousel images={block.images} />
                        </div>
                        
                        <div className="merchItemDesc">
                            {block.desc}
                        </div>
                        <div className="availColours">Available colours</div>
                        <ColourPanelMini />
                    </div>
                </li>
            )
        })
        return returnBlocks;
    }

    return (
        <div className="merchPanelOuter">
            <ul className='wrapPanel'>
                {renderBlocks()}
            </ul>
        </div>
    )
}

export default MerchPanel;