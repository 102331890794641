import './Create.css';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import axios from "axios";
import { Core3D, Material, Model } from '@core3d/sdk';
import { Adapter } from '@core3d/sdk-adapter-three'
import { useContext, useEffect, useRef, useState } from 'react';
import FooterNew from '../components/molecule/FooterNew/FooterNew';
import SizePanel from '../components/molecule/SizePanel/SizePanel';
import ImageThumbPanel from '../components/molecule/ImageThumbPanel/ImageThumbPanel';
import BuyButton from '../components/atom/BuyButton/BuyButton';
import { selectContext, operatingContext } from '../context/Context';
import ShoppingBasket from '../components/molecule/ShoppingBasket/ShoppingBasket';
import PlacementPanel from '../components/molecule/PlacementPanel/PlacementPanel';
import Loading from '../components/atom/Loading/Loading'
import Drawer from '../components/atom/Drawer/Drawer';
import Header from '../components/molecule/Header/Header';
import { useShoppingBasket } from '../context/Context';
import { Link, useNavigate } from 'react-router-dom';
import ColourPanel from '../components/molecule/ColourPanel/ColourPanel';

const Setup = () => {
  const {setUploadedImage} = useContext(selectContext);
  const {uploadedImageURI, selectedColour, selectedPlace, selectedSize} = useContext(selectContext);
  const { state } = useShoppingBasket();

  const [isRendering, setIsRendering] = useState(false);
 
  const { dispatch } = useShoppingBasket();
  let navigate = useNavigate();

  const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount) => {
    dispatch({
      type: 'ADD_ITEM',
      payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount }
    });
  };

  const handleEdit = (id, newCount) => {
    dispatch({ 
      type: 'EDIT_ITEM', 
      payload: { id: id, updates: { itemCount: newCount } } });
  };

  const core3d = new Core3D({
    apiKey: 'c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s',
    createAdapter: ctx => new Adapter(ctx),
  });

  const container = useRef(null)

  const setRGBColour = () => {
    let returnColour;
    switch(selectedColour) {
      case '#000000':
        returnColour = [0,0,0,1];
        break;
      case '#ff2d2d':
        returnColour = [1,0.176,0.176,1];
        break;
      case '#2d32ff':
        returnColour = [0.176,0.196,1,1];
        break;
      case '#73c881':
        returnColour = [0.451,0.784,0.506,1];
        break;
      case '#deeb06':
        returnColour = [0.871,0.922,0.024,1];
        break;
      default:
        returnColour = [1,1,1,1];
    }

    return returnColour;
  }

  const setPlacement = () => {
    if(selectedPlace === 'frontLeftPocket'){
      return 1;
    } else {
      return 0;
    }
  }

  const buildRender = async () => {
    setIsRendering(true);
    const design = core3d.createDesign();
    const model = await core3d.loadModel(Model.Tee);
    const cotton = await core3d.loadMaterial(Material.Cotton);
    const graphic = await core3d.loadGraphic(uploadedImageURI)

    // console.log('model', model)
    // console.log('cotton', cotton)

    design.setModel(model);

    // console.log('design', design)

    for (const mesh of design.listMeshes()) {
        // console.log(mesh)
        design.apply(cotton, mesh);
    }

    cotton.setOption('color', setRGBColour());
    const zone = design.listZones().find(zone => /front/i.test(zone.getName()));
    if ( zone ) {
      const assignment = design.apply(graphic, zone);
      const placement = zone.placements[setPlacement()];
      if (placement) {
        assignment.setPlacement(placement.name)
      }
    }

    await design.render();

    const scene = await core3d.loadScene({
      layout: 'front',
      size: [512, 512],
      target: design,
    });

    scene.fit();
    scene.start();

    // console.log('.....', scene)
    //document.body.append(scene.element);
    container.current.innerHTML = "";
    container.current.append(scene.element);
    setIsRendering(false);
  }

  const handleFileSelect = (e) =>{
    setUploadedImage(e.target.files[0])
  }

  const handleBasketAdd = () => {
    const itemName = 'Custom Round Neck T-Shirt';
    const sku = 'SKU7364287634'
    const size = selectedSize;
    const colour = selectedColour;
    const itemCost = 39.99
    const id = `${sku}-${uploadedImageURI}-${size}-${colour}`
    const result = state.items.filter(obj => { return obj.id === id})
    if(result.length === 0){
      handleAdd(id, itemName, sku, itemCost, size, colour, 1);
    } else {
      const currentCount = result[0].itemCount;
      handleEdit(id, currentCount + 1)
    }
    
  }

  // ***** Upload to Firebase storage *****
  // const handleUpload = () => {
  //   setIsUploading(true);
  //   console.log('upload', uploadedImage)
  //   const baseURL = "https://uploadfile-pcgrjii2ia-ew.a.run.app";
  //   const fd = new FormData();
  //   fd.append('image', uploadedImage)
  //   axios
  //     .post(baseURL, fd)
  //     .then((response) => {
  //       setIsUploading(false);
  //       console.log(response)
  //     }).catch(error => {
  //       console.log('ERROR', error)
  //     });
  // }

  // const handleUpload = () => {
  //   setIsUploading(true);
  //   const baseURL = "https://api.core3d.io/v1/uploads";
  //   const fd = new FormData();
  //   fd.append('file', uploadedImage)
  //   axios
  //     .post(baseURL, fd, { headers: { Authorization: `Bearer c3dak_NQCudjy8_8uZnX4oa6H9vEMWnirg29A2s` } })
  //     .then((response) => {
  //       setIsUploading(false);
  //       const imageURI = response.data.uri;
  //       setUploadedImageURI(imageURI)
  //       setUploadComplete(true)
  //     }).catch(error => {
  //       console.log('ERROR', error)
  //       setUploadComplete(false);
  //     });
  // }


  

  useEffect(() => {
    if(uploadedImageURI){
      //buildRender()
    }
  }, [selectedColour, selectedPlace, uploadedImageURI])

  
  return (
    
    <div id="container">
      <Header />
      {/* <Modal isOpen={isOpen} onClose={onClose} isCentered size={'md'} scrollBehavior='inside' closeOnOverlayClick={false} closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lets start at the start....</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className='modalTitle'>Upload an image to start creating your merchandise</div>
              <div className='modalSelect'>
                <input type='file' onChange={handleFileSelect}/>
                <div className='imageDisplay'>
                  {uploadedImage &&  <img src={preview} style={{'aspect-ratio': 'auto'}} alt='Uploaded' /> }
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <div className='uploadMessage'>
                {(isUploading)?<div className='uploading'>Uploading...</div>: ''}
                {(uploadComplete)?<div className='uploaded'>Image Uploaded.</div>:''}
              </div>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={handleUpload} isDisabled={(uploadComplete)?true:false}>Upload</Button>
              <Button style={{'background': '#ff0098'}} colorScheme='blue' mr={3} onClick={onClose} isDisabled={(uploadedImageURI)?false:true}>
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}
      <main>
        <section>
          <div className='createContent'>
            <Drawer />
            <div className='leftPanel'>
              <div className='collectionTitle'></div>
              <div className='specPanel'>
                <div className='panel'><ImageThumbPanel /></div>
                <div className='panel'><ColourPanel /></div>
              </div>
            </div>
            <div className='rightPanel'>
              <div className='collectionTitle'></div>
              <div className='designPanel'>
                <div className='designTitle'></div>
                <div className='design'>
                <div className='renderPanel'>2D Design Here</div>
                  {/* <Loading /> */}
                  {isRendering && <div className='renderPanel'>Placeholder. Please press continue to advance....</div> }
                  <div  ref={ container } />
                </div>
                <div className='designBlurb'></div>
              </div>
              <div className='drawerButtons'>
                <button className='continueButton' onClick={() => navigate(-1)}>Back</button>
                {/* {(isRendering) &&
                    <span className='continueButton continueDisabled'>Continue</span>
                }
                
                {(!isRendering) && */}
                    <Link className='continueButton' isdisabled={true} style={{'background': '#ff0098'}} to={`/create`}>Choose size and colour</Link>
                {/* } */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
           
  );
}

export default Setup;
