import { 
    Button,
    Card,
    CardBody,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Divider,
    CardFooter,
    ButtonGroup,
    IconButton,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    Lorem
} from "@chakra-ui/react";
import { selectContext, operatingContext } from '../../../context/Context';
import { ViewIcon, AddIcon } from '@chakra-ui/icons';
import SizePanelMini from '../SizePanelMini/SizePanelMini'
import ColourPanel from '../ColourPanel/ColourPanel'
import BuyButton from "../../atom/BuyButton/BuyButton";
import './ShopCard.scss';
import { useState, useContext } from "react";
import { useShoppingBasket } from '../../../context/Context';
import heartIcon from '../../../assets/svg/heart.svg'

const ShopCard = ( props ) => {
    const { details }  = props;
    const [isOpen, setIsOpen] = useState(false);
    const {selectedColour, selectedSize} = useContext(selectContext);
    const { state, dispatch } = useShoppingBasket();

    const handleQuickView = () => {
        setIsOpen(true)
    }

    const handleOnClose = () => {
        setIsOpen(false)
    }

    const handleAdd = (id, itemName, sku, itemCost, itemSize, itemColour, itemCount) => {
        dispatch({
          type: 'ADD_ITEM',
          payload: { id: id, name: itemName, sku: sku, itemCost: itemCost, itemColour, itemSize: itemSize, itemCount: itemCount }
        });
      };
    
      const handleEdit = (id, newCount) => {
        dispatch({ 
          type: 'EDIT_ITEM', 
          payload: { id: id, updates: { itemCount: newCount } } });
      };

    const handleBasketAdd = () => {
        const itemName = 'Custom Round Neck T-Shirt';
        const sku = 'SKU7364287634'
        const size = selectedSize;
        const colour = selectedColour;
        const itemCost = 39.99
        const id = `${sku}-${size}-${colour}`
        const result = state.items.filter(obj => { return obj.id === id})
        if(result.length === 0){
          handleAdd(id, itemName, sku, itemCost, size, colour, 1);
        } else {
          const currentCount = result[0].itemCount;
          handleEdit(id, currentCount + 1)
        }
        
      }

    return (
        <Card maxW='sm' className="shopCard">
            <CardBody>
                <Heading size='md' color={'#ff0098'}>{details.ItemName}</Heading>
                <Image
                height={'150px'}
                src={details.ItemImage}
                alt={details.ItemName}
                borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text color='#ff0098' fontSize='2xl'>{details.ItemCost}</Text>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter className="cardFooter">
                <ButtonGroup spacing='2'>
                {/* <Button variant='solid' colorScheme='blue'>
                    Buy now
                </Button> */}
                <Tooltip label='Quick View'>
                    <IconButton aria-label='Search database' onClick={handleQuickView} icon={<ViewIcon />} variant='outline' colorScheme='pink' color='#ff0098'  />
                    
                </Tooltip>
                <Tooltip label='Add to wishlist'>
                    {/* <IconButton aria-label='Search database' icon={<AddIcon />} variant='outline' colorScheme='pink' color='#ff0098' /> */}
                    <Button className="heartButton" aria-label="Add to Wishlist" variant='outline' colorScheme="pink" color='#ff0098'>
                        <img src={heartIcon} alt="Heart Icon" />
                    </Button>
                </Tooltip>
                <div className={"gradient-button"}>
                    <div class={"mintButton buy"}>
                    Buy
                    </div>
                </div>
                </ButtonGroup>
            </CardFooter>
            <Modal isOpen={isOpen} size={'xl'} onClose={handleOnClose}>
                <ModalOverlay />
                <ModalContent maxH="72vh" maxW="70vw">
                <ModalCloseButton />
                <ModalBody>
                    <HStack>
                        <Image
                            width={'450px'}
                            src={details.ItemImage}
                            alt={details.ItemName}
                            borderRadius='lg'
                            />
                        <VStack align={'left'}>
                            <div className="ShopCardTitle">{details.ItemName}</div>
                            <Text className="ShopCardText">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus dui magna, sit amet congue mauris laoreet quis. Praesent turpis ligula, fermentum sed risus sed, eleifend pretium metus. Quisque velit enim, venenatis at rhoncus ut, porttitor sed metus. Nam nibh nisi, interdum vel fringilla eu, tempor non sapien.</Text>
                            <SizePanelMini />
                            <ColourPanel type={'slim'}  />
                            <Text className="ShopCardPrice" color='#ff0098' fontSize='2xl'>{details.ItemCost}</Text>
                        </VStack>
                        
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    {/* <Button colorScheme='pink' mr={3} onClick={handleOnClose}>
                    Close
                    </Button>
                    <Button variant='ghost' onClick={handleBuy}>Add to Basket</Button> */}
                    <BuyButton returnClick={handleBasketAdd}/>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Card>
    )
}

export default ShopCard;