import { useState } from "react";

import './MerchCarousel.css'


const MerchCarousel = ( props )=>{

    const {images} =  props 
	
	const [selected, setSelected] = useState(1);
	
	let length = images.length;
	
	function handleClick (type){
		
		if(type === 'next'){
			setSelected((prev)=>prev < length ? prev+1 : 1);
		}
		else if(type === 'back'){
			setSelected((prev)=>prev === 1 ? length : prev-1 );
			
		}
	}
	
	return(
		 <div className="carouselWrapper">
			{images.map((d, index)=>{
				return(
					<div key={index} className = {`gh ${d.id === selected ? 'carouselActive' : 'carouselHidden'}`}>
                        <img className="baseImage" src={d.name}/>
					</div>
				)
			})}
			 <div className='caroButtonWrapper'>
				<button className={`caroButton ${(selected === 1) ? 'butActive' : ''}`} onClick={()=>handleClick('back')}></button>
				<button className={`caroButton ${(selected === 2) ? 'butActive' : ''}`} onClick={()=>handleClick('next')}></button>
			</div>
		</div>

		
	
	)
	
}

export default MerchCarousel;