import { useState, useEffect } from "react";
import { useShoppingBasket } from '../../../context/Context';

const CheckoutItem = (props) => {
    const { item, index } = props;
    const { state, dispatch } = useShoppingBasket();
    const [currentCount, setCurrentCount]= useState(0);
    const [itemTotal, setItemTotal] = useState(0);

    const handleDelete = () => {
        dispatch({ type: 'DELETE_ITEM', payload: item.id });
      };
    
      const handleEdit = (type) => {
        let current = currentCount;
        if (type === '-'){
            current = current - 1;
        } else {
            current = current + 1
        }
        dispatch({ 
          type: 'EDIT_ITEM', 
          payload: { id: item.id, updates: { itemCount: current } } });
      };

    useEffect(() => {
        setCurrentCount(item.itemCount)
        setItemTotal((item.itemCost*item.itemCount).toFixed(2))
        
    })

    return (
        <tr key={index}>
            <td><img src="https://via.placeholder.com/110" alt="Product Image"/></td>
            <td>{item.name}</td>
            <td>{item.itemCost}</td>
            <td>
                <div className="quantity-form">
                    <button className="incrementButton" disabled={(currentCount===0)?true:false} onClick={() => handleEdit('-')}>-</button>
                    <input type="text" className="checkoutQuantity" defaultValue={currentCount} readOnly/>
                    <button className="incrementButton" onClick={() => handleEdit('+')}>+</button>
                </div>
            </td>
            <td>{itemTotal}</td>
            <td>
                {/* <button className="remove-button" onClick={handleDelete}>✖ Remove</button> */}
                <button className="checkoutRemove" onClick={handleDelete}>REMOVE</button>
            </td>
        </tr>  
    )
    

}

export default CheckoutItem;