import './BuyButton.css';

const BuyButton = ( props ) => {
    const { returnClick, text='buy' } = props;

    const handleClick = () => {
        returnClick();
    }

    return (
        <button className={"gradient-button"} onClick={handleClick}>
            <div className={"mintButton buy"}>
                {text}
            </div>
        </button>
)
}

export default BuyButton;