import { useState, useEffect } from 'react';
import YaayLogoSmall from '../../atom/LogoSmall/LogoSmall';
import './Header.css';

const Header = () =>{

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
      });
    });
  
    return (
      <header>
          <div flex='1' className='headerWrapper'><YaayLogoSmall align={'center'}/></div>
        </header>
    )
  }

  export default Header;