import { useState, useContext } from 'react';
import { operatingContext, selectContext } from '../../../context/Context';
import './ImageBox.css'

const ImageBox = (props) => {
    const {imgId, imgSrc, imgSize, isSelected, returnClick} = props;
    const { imageAlbum, setImageAlbum } = useContext(operatingContext);
    const {confirmOpen, setConfirmOpen} = useContext(selectContext);
    const [showDelete, setShowDelete] = useState(false);

    const handleClick = () => {
            returnClick(imgId);
    }

    const handleMouseOver = () => {
        setShowDelete(true);
    }

    const handleMouseOut = () => {
        setShowDelete(false);
    }

    const handleDelete = () => {
        setConfirmOpen({state: true, imgId: imgId});
    }

    return (
        <div className={`imageBox ${(isSelected)?'imageSelected':''}`} onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div className={`delete ${(showDelete)?'showDelete':''}`}>
                <button className='imageDelete' onClick={handleDelete}>x</button>
            </div>
            <div className='albumImage'>
                <img className='albumImage' src={imgSrc} />
            </div>
        </div>
    )
}

export default ImageBox;