import { useState, useRef } from "react";
import './DragDrop.scss'

const DragDropFile = (props) => {
    const { returnBrowse, returnURL, returnDragDrop } = props;
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    function handleFile(files) {
        returnDragDrop(files)
      }
    
    // handle drag events
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files);
      }
    };
    
    const handleBrowse = () => {
        returnBrowse();
    }

    const handleURL = () => {
        returnURL();
    }
    
    return (
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        {/* <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} /> */}
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
          <div>
            <div className='uploadTitle'>Upload your images to create your unique merchandise.</div>
            <div className='uploadSubText'>You can drag and drop your files here or <span className='textLink' onClick={handleBrowse}>browse</span> your computer or add <span className='textLink' onClick={handleURL}> image URLs</span></div>
          </div> 
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>
    );
  };

  export default DragDropFile;