import './ColourPanel.css'
import ColourBox from '../../atom/ColourBox/ColourBox';
import { useContext } from 'react';
import { selectContext } from '../../../context/Context';

const ColourPanel = ({type}) => {
    const {selectedColour, setSelectedColour} = useContext(selectContext);
    const colours = ['#ffffff', '#000000','#c5c5c5','#303549','#c42b20'];

    const handleColourSelect = (colour) => {
        setSelectedColour(colour)
    }

    const renderColours = () => {
        const colourReturn = colours.map((colour, index) => {
            return <ColourBox key={index} colourVal={colour} isSelected={(selectedColour === colour)?true:false} returnClick={handleColourSelect}></ColourBox>
        })
        return colourReturn;
    }

    return (
        <div className='colourOuter'>
            Colour:
            <div className={(type==='slim')?'colourPanelSlim': 'colourPanel'}>
                {renderColours()}
            </div>
        </div>
    )
}

export default ColourPanel;