import PopShop from '../components/organism/PopShop/PopShop';
import YaayLogoSmall from '../components/atom/LogoSmall/LogoSmall';
import FooterNew from '../components/molecule/FooterNew/FooterNew';


import './Checkout.css'

const ShopPage = () => {


    
    
    return (
        <div id="container">
            {/* <Header /> */}
            <header>
                <div className='logoLeft'>
                <YaayLogoSmall align={'left'}/>
                </div>
                <div flex='1' className='createWrapper'>
                How you Create, Collaborate and Earn matters. Do it with YAAY
                </div>
            </header>
            <main>
                <section>
                    <div className="cart-page">
                        <PopShop />
                    </div>
                </section>
            </main>
            <footer>
                <FooterNew />
            </footer>
        </div>
    )
}

export default ShopPage;