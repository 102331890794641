import { useState } from 'react';
import YaayLogo from './components/atom/Logo/Logo';
import {Helmet} from "react-helmet";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { selectContext, operatingContext, ShoppingBasketProvider } from './context/Context';
import Create from './pages/Create';
import Setup from './pages/Setup';
import Upload from './pages/Upload';
import Merch from './pages/Merch';
import Home from './pages/Home';
import Shop from './pages/Shop';
import CheckoutPage from './pages/Checkout';
import {
  ChakraProvider,
  theme
} from '@chakra-ui/react';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/upload",
    element: <Upload/>,
  },
  {
    path: "/merch",
    element: <Merch/>,
  },
  {
    path: "/setup",
    element: <Setup/>,
  },
  {
    path: "/create",
    element: <Create/>,
  },
  {
    path: "/checkout",
    element: <CheckoutPage/>,
  },
  {
    path: "/shop",
    element: <Shop />
  }
]);

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [imageAlbum, setImageAlbum] = useState([])
  const [selectedImage, setSelectedImage] = useState();
  const [selectedColour, setSelectedColour] = useState('#ffffff');
  const [selectedPlace, setSelectedPlace] = useState('frontFull');
  const [selectedSize, setSelectedSize] = useState('L');
  const [selectedMerch, setSelectedMerch] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageURI, setUploadedImageURI] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState({state: false, imgId: null})


  const initialOptions = {
    "client-id": "AR5JkOOY2yTcFv3ORwDW4Q_HaT-p0viB_VEkOpsxbdApIluR0c47ZKyUapXZLouvbv44d_5p6QXgdE9e",
    currency: "HKD",
    intent: "capture",
  };

  function stopWheelZoom(event) {
    if (event.ctrlKey === true) {
      event.preventDefault();
    }
  }
  function stopKeyZoom(event) {
    if (event.ctrlKey && [48,61,96,107,109,187,189].indexOf(event.keyCode) > -1) {
      event.preventDefault();
    }
  }
  document.addEventListener("keydown", stopKeyZoom);
  document.addEventListener('mousewheel', stopWheelZoom);
  document.addEventListener('DOMMouseScroll', stopWheelZoom);

  
  return (
    <operatingContext.Provider value={{isDrawerOpen, setIsDrawerOpen, imageAlbum, setImageAlbum}}>
        <selectContext.Provider value={{selectedColour, setSelectedColour, selectedPlace, setSelectedPlace, uploadedImage, setUploadedImage, isOpen,  setIsOpen, selectedImage, setSelectedImage, uploadedImageURI, setUploadedImageURI, selectedSize, setSelectedSize, selectedMerch, setSelectedMerch, confirmOpen, setConfirmOpen}}>
          <ShoppingBasketProvider>
            <PayPalScriptProvider options={initialOptions}>
              <ChakraProvider theme={theme}>
                <div className="App">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>YAAY - Where Creators become entrepreneurs</title>
                    <meta name="description" content="YAAY - Leveling-up Creator Entrepreneurship in Web3" />
                    <meta property="og:title" content="YAAY - Where Creators become entrepreneurs"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.yaay.me"/>
                    <meta property="og:image" content={YaayLogo}/>
                  </Helmet>

                  <RouterProvider router={router} />
                </div>
              </ChakraProvider>
              </PayPalScriptProvider>
            </ShoppingBasketProvider>
          </selectContext.Provider>
        </operatingContext.Provider>
  );
}

export default App;
