import ImageBox from './ImageBox';
import { operatingContext, selectContext } from '../../../context/Context';
import './ImageAlbum.css'
import { useContext, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

const ImageAlbum = () => {

    const { imageAlbum } = useContext(operatingContext);
    const { selectedImage, setSelectedImage } = useContext(selectContext)
    const { setUploadedImageURI } = useContext(selectContext)

    const handleImageSelect = (imgId) => {
        setSelectedImage(imgId);
    }

    useEffect(() => {
        const index = imageAlbum.findIndex(item => item.id === selectedImage);
        const current = imageAlbum;
        const imageURI = current[index]?.uri;
        setUploadedImageURI(imageURI);
    }, [selectedImage])

    const renderImages = () => {
        if(imageAlbum.length < 1) return
        const imageReturn = imageAlbum.map((image, index) => {
            let imgSizes = {width: image.imgWidth, height: image.imgHeight};
            
            return <ImageBox key={index} imgId={image.id} imgSrc={image.src} imgSize={imgSizes} isSelected={(selectedImage === image.id)?true:false} returnClick={handleImageSelect}></ImageBox>
        })
        return imageReturn;
    }

    return (
        <div className='albumPanel'>
            {(imageAlbum.length===0) && <Box style={{color: '#ff0098'}}>To add an Image, upload using the form above</Box>}
            {renderImages()}
        </div>
    )
}

export default ImageAlbum;