import React, {useEffect, useContext, useState } from 'react';
import { useShoppingBasket } from '../../../context/Context';
import ShoppingItem from './ShoppingItem';
import AddItemForm from './AddItemForm';
import { operatingContext } from '../../../context/Context';

const ShoppingBasket = (props) => {
  const { items } = props
  const { setIsDrawerOpen } = useContext(operatingContext)
  const { state } = useShoppingBasket();
  
  useEffect(() => {
    if(items.length > 0){
        setIsDrawerOpen(true)
    }
    
  }, [state] )

  return (
    <div className='basketOuter'>
        {items.length === 0 &&
            <div className='noItems'>You currently don't have anything in your basket.</div>
        }
        {items.map(item => (
          <ShoppingItem key={item.id} item={item} />
        ))}
    </div>
  );
};

export default ShoppingBasket;