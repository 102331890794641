import EXIF from 'exif-js';

export const isValidFileExtension = (fileName) => {
    const allowedExtensions = /(\jpg|\jpeg|\png|\gif|\pdf)$/i;
    return allowedExtensions.test(fileName);
  };

  export const isValidFileSize = (file, maxSizeMB) => {
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    return file.size <= maxSizeBytes;
  };
  
  export const getImageDetails = async (file, callback) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    img.onload = () => {
      // Assume the standard display DPI is 96 (default for most screens)
      const standardDPI = 96;
  
      // We can't read DPI directly from the image metadata in the browser
      // But we can use a standard DPI to calculate width and height in inches
      const widthInInches = img.width / standardDPI;
      const heightInInches = img.height / standardDPI;
      const widthInCm = widthInInches * 2.54;
      const heightInCm = heightInInches * 2.54;

  
      callback({
        width: img.width,
        height: img.height,
        realWidth: widthInCm,
        realHeight: heightInCm,
        dpi: standardDPI
      });
  
      URL.revokeObjectURL(img.src);
    };
  };

export const getImageDPI = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    const image = new Image();
    image.src = event.target.result;

    image.onload = () => {
      EXIF.getData(image, function() {
        const xDPI = EXIF.getTag(this, 'XResolution');
        const yDPI = EXIF.getTag(this, 'YResolution');

        const dpi = {
          x: xDPI ? xDPI : 96, // Default to 96 if DPI is not found
          y: yDPI ? yDPI : 96  // Default to 96 if DPI is not found
        };

        callback(dpi);
      });
    };
  };

  reader.readAsDataURL(file);
};

export const getPngDPI = (file, callback) => {
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const dataView = new DataView(arrayBuffer);
  
      const pngSignature = '89504E470D0A1A0A';
      const signature = Array.from(new Uint8Array(arrayBuffer.slice(0, 8)))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('')
        .toUpperCase();
  
      if (signature !== pngSignature) {
        callback(new Error('Not a valid PNG file'));
        return;
      }
  
      let offset = 8;
      while (offset < dataView.byteLength) {
        const length = dataView.getUint32(offset);
        const type = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer.slice(offset + 4, offset + 8)));
  
        if (type === 'pHYs') {
          const xPixelsPerUnit = dataView.getUint32(offset + 8);
          const yPixelsPerUnit = dataView.getUint32(offset + 12);
          const unitSpecifier = dataView.getUint8(offset + 16);
  
          let dpi = {
            x: null,
            y: null
          };
  
          if (unitSpecifier === 1) { // unitSpecifier 1 means the unit is meters
            dpi.x = Math.round(xPixelsPerUnit * 0.0254);
            dpi.y = Math.round(yPixelsPerUnit * 0.0254);
          }
  
          callback(null, dpi);
          return;
        }
  
        offset += 12 + length;
      }
  
      callback(new Error('No pHYs chunk found'));
    };
  
    reader.readAsArrayBuffer(file);
  };

  export const getPngDPIFromURL = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const arrayBuffer = await response.arrayBuffer();
      const dataView = new DataView(arrayBuffer);
  
      const pngSignature = '89504E470D0A1A0A';
      const signature = Array.from(new Uint8Array(arrayBuffer.slice(0, 8)))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('')
        .toUpperCase();
  
      if (signature !== pngSignature) {
        throw new Error('Not a valid PNG file');
      }
  
      let offset = 8;
      while (offset < dataView.byteLength) {
        const length = dataView.getUint32(offset);
        const type = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer.slice(offset + 4, offset + 8)));
  
        if (type === 'pHYs') {
          const xPixelsPerUnit = dataView.getUint32(offset + 8);
          const yPixelsPerUnit = dataView.getUint32(offset + 12);
          const unitSpecifier = dataView.getUint8(offset + 16);
  
          let dpi = {
            x: null,
            y: null
          };
  
          if (unitSpecifier === 1) { // unitSpecifier 1 means the unit is meters
            dpi.x = Math.round(xPixelsPerUnit * 0.0254);
            dpi.y = Math.round(yPixelsPerUnit * 0.0254);
          }
  
          return dpi;
        }
  
        offset += 12 + length;
      }
  
      throw new Error('No pHYs chunk found');
    } catch (error) {
      throw new Error('Failed to fetch image');
    }
  };

  const handleCheckPNGDpi = async (imageUrl) => {
    try {
      const result = await getPngDPIFromURL(imageUrl);
      //setError('');
      return result;
    } catch (err) {
      //setError(err.message);
      console.log(err)
      return null
    }
  };

  export const getImageDetailsFromURL = async (imageUrl, callback) => {
    const ext = imageUrl.split('.').pop();
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This is important if the image is from a different origin
    img.src = imageUrl;
  
    img.onload = async () => {
      const width = img.width;
      const height = img.height;
      if(ext === 'png'){
        // here
        const pngDPI = await handleCheckPNGDpi(imageUrl)
        const standardDPI = pngDPI.x;
        const widthInInches = width / standardDPI;
        const heightInInches = height / standardDPI;
        const widthInCm = widthInInches * 2.54;
        const heightInCm = heightInInches * 2.54;

        callback(null, { 
          width: img.width,
          height: img.height,
          realWidth: widthInCm,
          realHeight: heightInCm, 
          dpi: pngDPI });
  
      } else {
        EXIF.getData(img, function() {
        const xDPI = EXIF.getTag(this, 'XResolution');
        const yDPI = EXIF.getTag(this, 'YResolution');
  
        const dpi = {
          x: xDPI || 96, // Default to 96 if DPI is not found
          y: yDPI || 96  // Default to 96 if DPI is not found
        };

        const standardDPI = dpi.x;
        const widthInInches = width / standardDPI;
        const heightInInches = height / standardDPI;
        const widthInCm = widthInInches * 2.54;
        const heightInCm = heightInInches * 2.54;
  
        callback(null, { 
          width: img.width,
          height: img.height,
          realWidth: widthInCm,
          realHeight: heightInCm, 
          dpi: dpi });
      });
      }
      
    };
  
    img.onerror = () => {
      callback(new Error('Failed to load image'));
    };
  };

  export const imageUrlToFile = async (imageUrl) => {
    const fileName = imageUrl.split('/').pop();
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };