import shop1 from './images/shop/FAERYSAOPHoodie.png'
import shop8 from './images/shop/FAERYSiPadCover.png'
import shop9 from './images/shop/FAERYSiPhone13Case.png'
import shop18 from './images/shop/FAERYSWaterbottle.png'

const images = [
    { id: 1, ItemImage: shop1, ItemName: 'Faery AOP Hoodie', ItemCost: '$125' },
    // { id: 1, ItemImage: shop7, ItemName: 'Faery Hoodie', ItemCost: '$90' },
    // { id: 1, ItemImage: shop15, ItemName: 'Faery T-Shirt', ItemCost: '$45' },
    // { id: 1, ItemImage: shop14, ItemName: 'Faery Socks (6)', ItemCost: '$55' },
    // { id: 1, ItemImage: shop16, ItemName: 'Faery Throw', ItemCost: '$175' },
    // { id: 1, ItemImage: shop6, ItemName: 'Faery Cushion (2)', ItemCost: '$90' },
    // { id: 1, ItemImage: shop3, ItemName: 'Faery Canvas', ItemCost: '$120' },
    // { id: 1, ItemImage: shop17, ItemName: 'Faery Tote Bag', ItemCost: '$45' },
    { id: 1, ItemImage: shop9, ItemName: 'Faery iPhone Case', ItemCost: '$75' },
    { id: 1, ItemImage: shop8, ItemName: 'Faery iPad 4 Cover', ItemCost: '$85' },
    // { id: 1, ItemImage: shop12, ItemName: 'Faery Notebook (2)', ItemCost: '$75' },
    // { id: 1, ItemImage: shop5, ItemName: 'Faery Round Clock', ItemCost: '$90' },
    { id: 1, ItemImage: shop18, ItemName: 'Faery Water Bottle', ItemCost: '$55' },
    // { id: 1, ItemImage: shop11, ItemName: 'Faery Mug (4)', ItemCost: '$120' },
    // { id: 1, ItemImage: shop10, ItemName: 'Faery Jigsaw', ItemCost: '$45' },
    // { id: 1, ItemImage: shop2, ItemName: 'Faery Badge (15)', ItemCost: '$45' },
  ];
  export default images;