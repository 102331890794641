import React, { useState, useEffect } from 'react';
import { useShoppingBasket } from '../../../context/Context';
import './ShoppingBasket.css'

const ShoppingItem = ({ item }) => {
  const { dispatch } = useShoppingBasket();
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState(item.name);
  const [currentCount, setCurrentCount]= useState(0)
  const [itemTotal, setItemTotal] = useState(0)


  const handleDelete = () => {
    dispatch({ type: 'DELETE_ITEM', payload: item.id });
  };

  const handleEdit = (type) => {
    let current = currentCount;
    if (type === '-'){
        current = current - 1;
    } else {
        current = current + 1
    }
    dispatch({ 
      type: 'EDIT_ITEM', 
      payload: { id: item.id, updates: { itemCount: current } } });
      setIsEditing(false);
  };

  const renderTotal = (itemPrice) => {
    return (itemPrice * 1)
  }

  const handleCount = (type) => {
    let current = currentCount;
    if (type === '-'){
        current = current - 1;
    } else {
        current = current + 1
    }
    setCurrentCount(current);
    setItemTotal((item.itemCost*current).toFixed(2))
  }

  const translateColour = ( colour ) => {
    let returnColour;
    switch(colour) {
      case '#000000':
        returnColour = 'Black';
        break;
      case '#ff2d2d':
        returnColour = 'Red';
        break;
      case '#2d32ff':
        returnColour = 'Blue';
        break;
      case '#73c881':
        returnColour = 'Green';
        break;
      case '#deeb06':
        returnColour = 'Glo Yellow';
        break;
      default:
        returnColour = 'White';
    }
    return returnColour;
  }

  useEffect(() => {
    setCurrentCount(item.itemCount)
    setItemTotal((item.itemCost*item.itemCount).toFixed(2))
    
  })

  return (
    <div className="basket-product">
    <div className="basketItem">
      <div className="product-image">
        <img src="http://placehold.it/120x166" alt="Placholder Image 2" className="product-frame"/>
      </div>
      <div className="product-details">
        <h1><strong>{item.name}</strong></h1>
        <p><strong>{translateColour(item.itemColour)}, Size {item.itemSize}</strong></p>
        <p>Product Code - {item.sku}</p>
      </div>
    </div>
    <div className='BasketLeft'>
        <div className="price">{item.itemCost}</div>
        <div className="quantity">
            <button className='incrementButton' disabled={(currentCount===0)?true:false} onClick={() => handleEdit('-')}>-</button>
            <div className='countNumber'>{currentCount}</div>
            <button className='incrementButton' onClick={() => handleEdit('+')}>+</button>
        </div>
        <div className="subtotal">{renderTotal(itemTotal)}</div>
        <div className="remove">
        <button onClick={handleDelete}>Remove</button>
        </div>
    </div>
  </div>
  );
};

export default ShoppingItem;